import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import FeedbackService from '../service/FeedbackService';
import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import NotifyController from '../utilities/Toast';

function OutfitGroupConfigModal({ onClose }: { onClose: Function }) {
    const [outfitGroupConfigs, setOutfitGroupConfigs] = useState<
        Array<{
            id: string;
            order: number;
            groupName: string;
        }>
    >([]);
    const [outfitGroupConfigsData, setOutfitGroupConfigsData] = useState<any>({});
    const { handleSubmit, setValue, register } = useForm();

    const getListOutfitGroupConfigs = () => {
        FeedbackService.getInstance()
            .getListOutfitGroupConfigs()
            .then(({ data }) => {
                setOutfitGroupConfigs(
                    Object.keys(data).map((key) => {
                        setValue(key, data[key].order);
                        return {
                            id: key,
                            ...data[key]
                        };
                    }).sort((o1, o2) => o1.order - o2.order)
                );
                setOutfitGroupConfigsData(data);
            });
    };

    const onSubmit = (formData: any) => {
        Object.keys(formData).forEach((key) => {
            if (outfitGroupConfigsData[key]) {
                outfitGroupConfigsData[key] = {
                    ...outfitGroupConfigsData[key],
                    order: +formData[key]
                }
            }
        });
        FeedbackService.getInstance().updateListOutfitGroupConfigs(outfitGroupConfigsData).then(() => {
            NotifyController.success('Updated');
            onClose();
        })
    };

    useEffect(() => {
        getListOutfitGroupConfigs();
    }, []);

    return (
        <Dialog header="Import Template" visible={true} style={{ width: '70vw' }} onHide={() => onClose()}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="p-fluid">
                    {outfitGroupConfigs.map((o, index) => {
                        return (
                            <div className="p-field mb-3" key={`o_${index}`}>
                                <label htmlFor={o.id}>{o.groupName}</label>
                                <InputText className="mt-2" type="number" id={o.id} {...register(o.id)} />
                            </div>
                        );
                    })}
                </div>

                {/* Buttons */}
                <div className="p-mt-2">
                    <Button label="Submit" type="submit" className="p-button-success mr-3" />
                    <Button label="Cancel" onClick={() => onClose(false)} className="p-button-secondary p-button-outlined p-ml-2" />
                </div>
            </form>
        </Dialog>
    );
}

export default OutfitGroupConfigModal;
