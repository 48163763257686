import React, { useCallback, useEffect, useState } from 'react';
import Papa from 'papaparse';
import { Dialog } from 'primereact/dialog';
import ReactJson from 'react-json-view'
import { Button } from 'primereact/button';
import FeedbackService from '../../service/FeedbackService';
import NotifyController from '../../utilities/Toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputSwitch, InputSwitchChangeParams } from 'primereact/inputswitch';
interface CsvRow {
    [key: string]: string;
}

function ImportTemplate({ onClose, visible, setIsShowImportModal }: { onClose: Function, setIsShowImportModal: Function, visible: boolean }) {
    const [csvData, setCsvData] = useState<CsvRow[]>([]);
    const [jsonData, setJsonData] = useState<string | null>(null);
    const [isJSON, setIsJSON] = useState<boolean>(false);

    const csvUploadFn = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const fileType = file.name.split('.').pop()?.toLowerCase();
        if (fileType === 'json' || fileType === 'csv') {
            if (fileType === 'csv') {
                Papa.parse<CsvRow>(file, {
                    complete: (result) => {
                        // Filter out unwanted fields from each row
                        const filteredData = result.data.map((row) => {
                            return Object.fromEntries(
                                Object.entries(row).filter(
                                    ([key, value]) => key && key !== "Created" && key !== "Updated"
                                )
                            );
                        });
                        setCsvData(filteredData);
                    },
                    header: true,
                });
            } else {
                try {
                    if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                            try {
                                setJsonData(e?.target?.result as string);
                            } catch (error) {
                                console.error("Error read JSON:", error);
                            }
                        };
                        reader.readAsText(file);
                    }
                } catch (error) {
                    console.error("Error read JSON:", error);
                }
            }

        } else {
            alert('Please upload a JSON or CSV file.');
            setJsonData(null)
        }

    };

    const conversionFn = () => {
        const mappedData = csvData.map(row => ({
            group: row["Group"] || "",
            outfit_code: row["Code"] || "",
            type: row["Type"] || "",
            avatar_url: row["Avatar"] || "",
            image_url: row["Image"] || "",
            prompt: row["Prompt"] || "",
        }));
        const res = JSON.stringify(mappedData, null, 2);
        setJsonData(res);
    };
    const onImportTemplate = useCallback(() => {
        if (!jsonData) return
        const confirmed = window.confirm("Please confirm the upload!");
        if (confirmed) {
            const data = JSON.parse(jsonData);
            FeedbackService.getInstance()
                .importTemplateV3(data)
                .then(() => {
                    NotifyController.success('Import success');
                    onClose();
                    setIsShowImportModal(false)
                })
                .catch((error) => {
                    NotifyController.error(error?.message);
                    console.log(error);
                    setIsShowImportModal(false)
                });
        }
    }, [jsonData])
    const onDownloadJson = useCallback(() => {

        if (!jsonData) return
        const currentTime = new Date().toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' })
            .replace(/\//g, '_');
        const fileName = "template_" + currentTime;
        const blob = new Blob([jsonData], { type: "application/json" });
        const href = URL.createObjectURL(blob);
        // create "a" HTLM element with href to file
        const link = document.createElement("a");
        link.href = href;
        link.download = fileName + ".json";
        document.body.appendChild(link);
        link.click();
        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }, [jsonData])
    useEffect(() => {
        if (csvData) {
            setTimeout(() => {
                conversionFn()
            }, 500)
        }
    }, [csvData])
    return (
        <Dialog header="Import Template" visible={visible} style={{ width: '70vw' }} onHide={() => setIsShowImportModal(false)}>
            <div className="header flex gap-2">
                <div>
                    <div className='flex flex-column'>
                        < input
                            type="file"
                            onChange={csvUploadFn}
                            accept=".csv,.json"
                        /></div>
                </div>
                <div>
                </div>
                <div className='flex flex-row flex-wrap gap-2' >

                    <Button className="p-button-info h-2rem" disabled={jsonData === '[]'} onClick={onDownloadJson}>
                        Download JSON
                    </Button>
                    <Button className="p-button-success h-2rem" disabled={jsonData === '[]'} onClick={onImportTemplate}>
                        Import
                    </Button>
                </div>
            </div>
            <div className="content mt-2">
                {jsonData && (
                    <div className="data-container">
                        <ReactJson src={JSON.parse(jsonData)} theme={'ashes'} collapsed={true}
                            onDelete={(e) => {
                                setJsonData(JSON.stringify(e.updated_src))
                            }}
                            onEdit={(edit) => {
                                console.log('edit ', edit);
                                setJsonData(JSON.stringify(edit.updated_src))
                            }} />
                    </div>
                )}
            </div>
        </Dialog>
    );
}

export default ImportTemplate;
