import React, { useState, useCallback } from 'react';
import { formatImages, uploadFileToS3 } from '../../utilities/function';
import ReactJson from 'react-json-view';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import FeedbackService from '../../service/FeedbackService';
import NotifyController from '../../utilities/Toast';
import { ProgressBar } from 'primereact/progressbar';
import { log } from 'console';
import { Checkbox } from 'primereact/checkbox';

type FileNode = {
    name: string;
    isFolder: boolean;
    path: string;
    children?: FileNode[];
};


type UploadFolderProps = {
    visible: boolean;
    setVisible: Function;
    onClose: Function

}
const UploadFolder = ({ visible, setVisible, onClose }: UploadFolderProps) => {
    const [folderStructure, setFolderStructure] = useState<{ [key: string]: FileNode }>({});
    const [results, setResults] = useState<string | null>(null);
    const [isPublic, setIsPublic] = useState<boolean>(true);
    const [progress, setProgress] = useState<number>(0.0);
    const processUploadedFiles = useCallback((files: FileList) => {
        const folderTree: { [key: string]: FileNode } = {};
        Array.from(files).forEach((file) => {
            const pathParts = file.webkitRelativePath.split('/');
            const rootFolder = pathParts[0]; // Get the root folder

            // Initialize the root folder if it doesn't exist
            if (!folderTree[rootFolder]) {
                folderTree[rootFolder] = { name: rootFolder, isFolder: true, path: rootFolder, children: [] };
            }

            let currentNode = folderTree[rootFolder];

            // Process each part of the path
            for (let i = 1; i < pathParts.length; i++) {
                const part = pathParts[i];

                // If it's the last part, it's a file
                if (i === pathParts.length - 1) {
                    currentNode.children?.push({ name: part, isFolder: false, path: file.webkitRelativePath });
                } else {
                    // Check if the folder already exists
                    let foundNode = currentNode.children?.find((child) => child.name === part && child.isFolder);
                    if (!foundNode) {
                        // If not found, create a new folder node
                        foundNode = { name: part, isFolder: true, path: `${currentNode.path}/${part}`, children: [] };
                        currentNode.children?.push(foundNode);
                    }
                    currentNode = foundNode;
                }
            }
        });

        return folderTree;
    }, []);
    const handleUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (!files) return;

        setProgress(0);
        setTimeout(async () => {
            const folderTree = processUploadedFiles(files);
            setFolderStructure(folderTree);

            // Dynamically find 'image' and 'thumb' folders in all root folder names
            const imageFolder = Object.values(folderTree).find((folder) =>
                folder.children?.some((child) => child.name === 'image')
            )?.children?.find((child) => child.name === 'image');

            const thumbnailFolder = Object.values(folderTree).find((folder) =>
                folder.children?.some((child) => child.name === 'thumb')
            )?.children?.find((child) => child.name === 'thumb');

            if (!imageFolder) {
                console.warn('Folder "image" does not exist in the upload structure.');
                return;
            }

            if (!thumbnailFolder) {
                console.warn('Folder "thumb" does not exist in the upload structure.');
                return;
            }

            const matchedResults: any[] = [];

            // Calculate total number of files (images + thumbnails) to upload
            const totalImageFiles = imageFolder.children?.reduce((acc: any, subFolder: any) => acc + subFolder?.children.length, 0) || 0;
            const totalThumbnailFiles = thumbnailFolder.children?.reduce((acc: any, subFolder: any) => acc + subFolder?.children.length, 0) || 0;
            const totalFilesToUpload = totalImageFiles + totalThumbnailFiles;

            let processedFiles = 0;

            // Sequentially upload images and thumbnails in each group
            for (const subFolder of imageFolder.children || []) {
                const thumbnailSubFolder = thumbnailFolder.children?.find(
                    (thumb) => thumb.name === subFolder.name
                );

                const imageUploads: string[] = [];
                const outfitCode: string[] = []
                for (const fileNode of subFolder.children || []) {
                    const file = Array.from(files).find((f) => f.webkitRelativePath === fileNode.path);
                    if (file) {
                        const resultURL = await uploadFileToS3(file, file.name, file.type);
                        imageUploads.push(resultURL);
                        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
                        outfitCode.push(fileNameWithoutExtension);
                    }
                    processedFiles += 1;
                    setProgress(Math.round((processedFiles / totalFilesToUpload) * 100));  // Update progress based on total files
                }

                const thumbnailUploads: string[] = [];
                if (thumbnailSubFolder) {
                    for (const fileNode of thumbnailSubFolder.children || []) {
                        const file = Array.from(files).find((f) => f.webkitRelativePath === fileNode.path);
                        if (file) {
                            const resultURL = await uploadFileToS3(file, file.name, file.type);
                            thumbnailUploads.push(resultURL);
                        }
                        processedFiles += 1;
                        setProgress(Math.round((processedFiles / totalFilesToUpload) * 100));  // Update progress based on total files
                    }
                }

                matchedResults.push({
                    group: subFolder.name,
                    image: imageUploads,
                    thumbnail: thumbnailUploads.length > 0 ? thumbnailUploads : ['No matching thumbnail'],
                    outfitCode: outfitCode
                });
            }

            const finalUpload = formatImages(matchedResults);
            setResults(JSON.stringify(finalUpload))
            // Set results only if different
            // setResults((prevResults: any) => {
            //     return JSON.stringify(prevResults) !== JSON.stringify(finalUpload) ? finalUpload : prevResults;
            // });
        }, 200)

    }, [isPublic])

    const onSave = useCallback(() => {
        if (!results) return
        const confirmed = window.confirm("Please confirm the upload!");
        if (confirmed) {
            const data = JSON.parse(results);
            FeedbackService.getInstance()
                .importTemplateV3(data)
                .then(() => {
                    NotifyController.success('Upload success');
                    onClose && onClose();
                    setVisible(false)
                })
                .catch((error) => {
                    NotifyController.error(error?.message);
                    console.log(error);
                    setVisible(false)
                });
        }
    }, [results])
    const onChangePublic = (e: any) => {
        setIsPublic(e.checked)
    }
    return (
        <Dialog visible={visible} onHide={() => setVisible(false)} header={<h5>Upload folder templates</h5>}>
            <div className='flex flex-column gap-4'>
                <div>
                    <a href="https://docs.google.com/document/d/1RVJxV1KPjBa7U3WHOj-NIYA7jQ9MnU_ne4UYB7PMyH4" target='_blank'>Xem hướng dẫn</a>
                </div>
                <div>
                    <input
                        type="file"
                        multiple
                        ref={(input) => input && input.setAttribute('webkitdirectory', 'true')}
                        onChange={handleUpload}
                    />
                    <Button label="Save" className="p-button-success" disabled={progress !== 100} onClick={onSave} />
                </div>

                {progress > 0 && <div className='flex flex-column justify-content-center align-items-center'>
                    <span>
                        Việc tải lên có thể mất nhiều thời gian
                    </span>
                    <span>
                        Vui lòng giữ cho quá trình tải lên hoàn tất!
                    </span>
                </div>}

                {progress > 0 && <div style={{ margin: '20px 0' }}>
                    <ProgressBar value={progress} aria-label="Status" style={{
                        height: 20
                    }}></ProgressBar>
                </div>}

                {results &&
                    <div className="data-container">
                        <ReactJson
                            theme={'ashes'}
                            src={JSON.parse(results)}
                            collapsed
                            onDelete={(e) => setResults(JSON.stringify(e.updated_src))}
                            onEdit={(e) => setResults(JSON.stringify(e.updated_src))}
                        />
                    </div>
                }
            </div>

        </Dialog >
    );
};

export default UploadFolder;
