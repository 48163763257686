import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FeedbackService from '../service/FeedbackService';
import NotifyController from '../utilities/Toast';

const UpdateTemplateModal = ({ id, onClose }: { id: string | null; onClose: Function }) => {
    const { register, handleSubmit, setValue, watch } = useForm<{
        _id: string;
        group: string;
        code: string;
        type: string;
        prompt: string;
        avatarUrl: string;
        imageUrl: string;
    }>({
        defaultValues: {
            _id: '',
            group: '',
            code: '',
            type: '',
            prompt: '',
            avatarUrl: '',
            imageUrl: ''
        }
    });
    const watchFields = watch(['avatarUrl', 'imageUrl']);

    const [ready, setReady] = useState(false);

    const onSubmit = (formData: any) => {
        if (formData.group === '' || formData.avatarUrl === '' || formData.imageUrl === '' || formData.code === '') {
            NotifyController.error('Please fill all fields');
            return;
        }
        const data = {
            _id: formData._id,
            group: formData.group,
            outfit_code: formData.code,
            type: formData.type,
            avatar_url: formData.avatarUrl,
            image_url: formData.imageUrl,
            prompt: formData.prompt
        };

        FeedbackService.getInstance()
            .updateTemplate(data)
            .then(() => {
                NotifyController.success('Update success');
                onClose();
            })
            .catch((error) => {
                NotifyController.error(error?.message);
                console.log(error);
            });
    };

    const myUploader = async (event: any, type: string) => {
        try {
            const file = event.target.files[0];
            // const formData = new FormData();
            // formData.append('file', file);

            const res = await FeedbackService.getInstance().getSignedUrl(file.name, file.type);
            const signedRequest = res.data.signedRequest;

            // Perform a PUT request to the signed URL
            const response = await fetch(signedRequest, {
                method: 'PUT',
                body: file,
                headers: {
                    'Content-Type': file.type
                }
            });

            if (response.ok) {
                NotifyController.success('Image uploaded successfully');
                if (type === 'avatar') {
                    setValue('avatarUrl', res.data.url);
                } else if (type === 'image') {
                    setValue('imageUrl', res.data.url);
                }
            } else {
                console.error('Failed to upload image');
            }
        } catch (error: any) {
            console.error('Error uploading image:', error);
            NotifyController.error(error?.message);
        }
    };

    useEffect(() => {
        if (id) {
            FeedbackService.getInstance()
                .getTemplateDetail(id)
                .then((res) => {
                    if (res.success) {
                        const data = res.data;
                        setValue('_id', data._id);
                        setValue('prompt', data.prompt);
                        setValue('group', data.group);
                        setValue('type', data.type);
                        setValue('imageUrl', data.image_url);
                        setValue('code', data.outfit_code);
                        setValue('avatarUrl', data.avatar_url);
                    }
                    setReady(true);
                })
                .catch((error) => {
                    NotifyController.error(error?.message);
                    console.log(error);
                });
        }
    }, []);

    if (!ready) return null;

    return (
        <Dialog header="Update Template" visible={true} style={{ width: '50vw' }} onHide={() => onClose(false)}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* Form */}
                <div className="p-fluid">
                    <div className="p-field mb-3">
                        <label htmlFor="group">Group</label>
                        <InputText id="group" {...register('group')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="code">Code</label>
                        <InputText id="code" {...register('code')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="type">Type</label>
                        <InputText id="type" {...register('type')} />
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="avatarUrl">Avatar URL</label>
                        <input id="avatarUrl" type="file" name="avatar" accept="image/*" onChange={(e) => myUploader(e, 'avatar')} />
                        <div className="mt-2">
                            <span>{watchFields[0]}</span>
                        </div>
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="imageUrl">Image URL</label>
                        <input id="imageUrl" type="file" name="image" accept="image/*" onChange={(e) => myUploader(e, 'image')} />
                        <div className="mt-2">
                            <span>{watchFields[1]}</span>
                        </div>
                    </div>
                    <div className="p-field mb-3">
                        <label htmlFor="prompt">Prompt</label>
                        <InputTextarea id="prompt" {...register('prompt')} />
                    </div>
                </div>

                {/* Buttons */}
                <div className="p-mt-2">
                    <Button label="Submit" type="submit" className="p-button-success mr-3" />
                    <Button label="Cancel" onClick={() => onClose(false)} className="p-button-secondary p-button-outlined p-ml-2" />
                </div>
            </form>
        </Dialog>
    );
};

export default UpdateTemplateModal;
